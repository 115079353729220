import { useCallback, useState, useContext } from "react";
import Modal from "../../../../components/utility/Modal/Modal"
import TideEntitySelect from "../../../../components/utility/TideEntitySelect/TideEntitySelect";
import { satCatalogSGroups } from "../../../../services/modelUtils/taxDocumentUtils";
import useFormState from "../../../../hooks/useFormState";
import WindInput from "../../../../components/formComponents/WindInput/WindInput";
import { getNotifier } from "../../../../services/notifier";
import { ApiContext } from "../../../../services/api/api-config";
import './CancelInvoiceModal.scss'

const CancelInvoiceModal = ({onClose, invoiceData}) =>{

    const api = useContext(ApiContext);
    const {form, bindSimple, setForm} = useFormState({rfc: invoiceData.rfc, uuid: invoiceData.uuidFolioFiscal , reason:null, uuidSubstitute: null});
    const [disableConfirm, setDisableConfirm] = useState(false);
    const [substituteUUID, setSubstituteUUID] = useState(false)

    const close = useCallback(() => {
        if (onClose) onClose()
    }, [onClose]);

    const cancelInvoice = async() => {
        if(form.reason === null){
            getNotifier().warning("Ingresa un motivo de cancelación")
            return
        }
        if(form.reason.catalogKey === "01" && form.uuidSubstitute === null){
            getNotifier().warning("Ingresa el UUID de la nueva factura")
            return
        }
        setDisableConfirm(true)
        const {CancelacionCfdiConXMLResult} = await api.taxDocuments.cancelCfdi(form)

        if(!CancelacionCfdiConXMLResult.Success){
            getNotifier().error(`Error al cancelar: ${CancelacionCfdiConXMLResult.ErrDesc || 'Desconocido'}`)
            setDisableConfirm(false)
            return
        }else{
            getNotifier().success(CancelacionCfdiConXMLResult.Msg)
            close()
        }
    }

    const handleReasonChange = (e) => {
        setForm((prevForm) => ({
            ...prevForm,
            reason: e,
        }));

        e.catalogKey === "01" ? setSubstituteUUID(true) : setSubstituteUUID(false)
    }

    return(
        <Modal title={"Cancelar Factura"}
        onClose={close}
        mainButtonAction={cancelInvoice}
        mainButtonDisabled={disableConfirm}
        mainButtonText="Confirmar"
        secondaryButtonAction={close}
        secondaryButtonText="Cancelar"
        className={"cancel-modal"}
        >
            <p>¿Estas seguro que deseas cancelar esta factura? Esta acción es irreversible</p>

            <div className="input-row">
                <label>{"Motivo de cancelación"}</label>
                <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Motivo'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_MotivoCancelacion"
                        }}
                        filterBy={'description'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        preload
                        value={form.reason}
                        onChange={handleReasonChange}
                />
            </div>
            

            {substituteUUID && 
                <div className="input-row">
                    <WindInput placeholder={"UUID sustituto"}
                        {...bindSimple('uuidSubstitute')}
                    />
                </div>
            }        
        </Modal>
    )
}

export default CancelInvoiceModal