import React, { useContext, useEffect, useState } from "react";
import useFormState from "../../../../../hooks/useFormState";
import './Customization.scss';
import DragAndDrop from "../../../../../components/utility/DragAndDrop/DragAndDrop";
import { ApiContext } from "../../../../../services/api/api-config";
import { getNotifier } from "../../../../../services/notifier";
import useCallbackCreator from "use-callback-creator";
import WindInput from "../../../../../components/formComponents/WindInput/WindInput";
import Button from "../../../../../components/utility/Button/Button";
import homeIcon from "../../../../../assets/images/home-icon.svg"
import naviIcon from "../../../../../assets/images/navi-icon.svg"
import financeIcon from "../../../../../assets/images/finance-icon.svg"
import userIcon from "../../../../../assets/images/user-icon.svg"
import projectsIcon from "../../../../../assets/images/projects-icon.svg"
import clientIcon from "../../../../../assets/images/client-icon.svg"

const tenantSGroups = [
    'tenant_read',
    'tenant_read_logo'
]

const Customization = () => {
    const api = useContext(ApiContext);
    const [tenant, setTenant] = useState();
    const [tenantLogo, setTenantLogo] = useState();
    const [saveBtn, setSaveBtn] = useState(false);
    const [saveSectionPreferencesBtn, setSaveSectionPreferencesBtn] = useState(false)
    const { form, bindSimple, setForm } = useFormState();

    useEffect(() => {
        let isMounted = true;

        const fetchTenant = async () => {
            try {
                const tenantRfcs = await api.tenantRfcs.get();

                if (tenantRfcs.length > 0) {
                    const tenantId = tenantRfcs[0].tenant.split('/').pop();
                    const tenant = await api.tenants.get({ id: tenantId, params: { sGroups: tenantSGroups } });
                    const logoId = tenant?.companyLogo?.split('/').pop();
                    const tenantLogo = await api.appFiles.getUrl({ id: logoId });

                    if (isMounted) {
                        setTenant(tenant);
                        setTenantLogo(tenantLogo);
                        setForm({
                            primaryColor: tenant?.primaryColor || '#000000',
                            secondaryColor: tenant?.secondaryColor || '#000000',
                            sectionPreferences: JSON.parse(tenant?.sectionPreferences)
                        });
                        setSaveBtn(false);
                    }
                }
            } catch (error) {
                if (isMounted) {
                    console.error("Error fetching tenant data:", error);
                }
            }
        };

        fetchTenant();

        return () => {
            isMounted = false;
        };
    }, [api, setForm]);

    const handleSectionNameChange = useCallbackCreator((name, value) => {
        if(!saveSectionPreferencesBtn){
            setSaveSectionPreferencesBtn(true)
        }

        setForm((prevForm) => ({
            ...prevForm,
            sectionPreferences: {
                ...prevForm.sectionPreferences,
                [name]: value,
            },
        }));
    }, [setForm]);

    const saveSectionPreferences = async() => {

        try{
            const preferences = JSON.stringify(form.sectionPreferences)

            const response = await api.tenants.update({id: tenant.id, params:{sectionPreferences: preferences}})
            if(response){
                getNotifier().success("Secciones actualizadas correctamente")
                setSaveSectionPreferencesBtn(false)
                window.location.reload()
            }
        }catch(e){
            getNotifier().error(`Error al actualizar secciones ${e.message}`)
        }

    }

    const saveLogo = async (file, setDroppedFiles) => {
        try {
            const updatedTenant = await api.tenants.update({ id: tenant.id, params: { sGroups: tenantSGroups }, files: file });

            const logoId = updatedTenant?.companyLogo?.split('/').pop();
            const updatedTenantLogo = await api.appFiles.getUrl({ id: logoId });

            setTenantLogo(updatedTenantLogo);
            setDroppedFiles(null);

            getNotifier().success("Logotipo guardado correctamente");
        } catch (e) {
            getNotifier().error("Error al guardar logotipo");
        }
    };

    const saveColors = async () => {
        try {
            await api.tenants.update({ id: tenant.id, params: { ...form } });
            setSaveBtn(false);
            getNotifier().success("Colores guardados correctamente");
        } catch (e) {
            getNotifier().error("Error al guardar colores");
        }
    };

    return (
        <div className="customization-container">
            <h2>Logotipo</h2>
            <div className="inner-container">
                <div className="logo-block">
                    <p>Carga tu logo</p>
                    <DragAndDrop onSave={saveLogo} />
                </div>
                {tenant?.companyLogo &&
                    <div className="logo-block">
                        <p>Logo actual</p>
                        <img src={`${tenantLogo}`} alt="" className="logo-img" />
                    </div>
                }
            </div>

            <div className="outer-container">
                <h2>Colores</h2>
                <p>Personaliza los colores de tu empresa</p>
                <div className="inner-container">
                    <WindInput
                        className="color-input"
                        placeholder={"Seleccione color principal"}
                        type="color"
                        value={form.primaryColor}
                        onChange={(e) => {
                            const { onChange } = bindSimple('primaryColor');
                            if (onChange) onChange(e);
                            setSaveBtn(true);
                        }}
                    />

                    <WindInput
                        className="color-input"
                        placeholder={"Seleccione color secundario"}
                        type="color"
                        value={form.secondaryColor}
                        onChange={(e) => {
                            const { onChange } = bindSimple('secondaryColor');
                            if (onChange) onChange(e);
                            setSaveBtn(true);
                        }}
                    />
                </div>

                {saveBtn &&
                    <div className="btnContainer">
                        <Button onClick={saveColors}>Guardar</Button>
                    </div>
                }
            </div>


            <div className="outer-container">
                <h2>Secciones</h2>
                <p>Personaliza los titulos de las secciones del menú de navegación.</p>

                <div className="custom-section-grid">
                    <div>
                        <div className="custom-section-div">
                            <img src={homeIcon} alt="icon" className="icon-img"/>
                            <WindInput
                            placeholder={"Inicio"}
                            withLabel={false}
                            value={form.sectionPreferences?.dashboard}
                            onChange={handleSectionNameChange('dashboard')}/>
                        </div>

                        <div className="custom-section-div">
                            <img src={naviIcon} alt="icon" className="icon-img"/>
                            <WindInput
                            placeholder={"Navi"}
                            withLabel={false}
                            value={form.sectionPreferences?.navi}
                            onChange={handleSectionNameChange('navi')}
                            />
                        </div>

                        <div className="custom-section-div">
                            <img src={projectsIcon} alt="icon" className="icon-img"/>
                            <WindInput
                            placeholder={"Proyectos"}
                            withLabel={false}
                            value={form.sectionPreferences?.projects}
                            onChange={handleSectionNameChange('projects')}
                            />
                        </div>

                        <div className="custom-nested-section-div">
                        <WindInput
                            placeholder={"Cotizaciones"}
                            withLabel={false}
                            value={form.sectionPreferences?.quotes}
                            onChange={handleSectionNameChange('quotes')}
                            />
                        </div>

                        <div className="custom-section-div">
                            <img src={clientIcon} alt="icon" className="icon-img"/>
                            <WindInput
                            placeholder={"Clientes"}
                            withLabel={false}
                            value={form.sectionPreferences?.clients}
                            onChange={handleSectionNameChange('clients')}
                            />
                        </div>

                        <div className="custom-section-div">
                            <img src={userIcon} alt="icon" className="icon-img"/>
                            <WindInput
                            placeholder={"Usuarios"}
                            withLabel={false}
                            value={form.sectionPreferences?.users}
                            onChange={handleSectionNameChange('users')}
                            />
                        </div>
                    </div>

                    <div>
                        

                        <div className="custom-section-div">
                            <img src={financeIcon} alt="icon" className="icon-img"/>
                            <WindInput
                            placeholder={"Finanzas"}
                            withLabel={false}
                            value={form.sectionPreferences?.finance}
                            onChange={handleSectionNameChange('finance')}
                            />
                        </div>

                        <div className="custom-nested-section-div">
                            <WindInput
                                placeholder={"Transacciones"}
                                withLabel={false}
                                value={form.sectionPreferences?.['finance/transactions']}
                                onChange={handleSectionNameChange('finance/transactions')}
                                />
                        </div>
                        <div className="custom-nested-section-div">
                            <WindInput
                                placeholder={"Cuentas"}
                                withLabel={false}
                                value={form.sectionPreferences?.['finance/accounts']}
                                onChange={handleSectionNameChange('finance/accounts')}
                                />
                        </div>
                        <div className="custom-nested-section-div">
                            <WindInput
                                placeholder={"Pagos recurrentes"}
                                withLabel={false}
                                value={form.sectionPreferences?.['finance/recurringTransactions']}
                                onChange={handleSectionNameChange('finance/recurringTransactions')}
                                />
                        </div>
                        <div className="custom-nested-section-div">
                            <WindInput
                                placeholder={"Pagos recurrentes"}
                                withLabel={false}
                                value={form.sectionPreferences?.invoices}
                                onChange={handleSectionNameChange('invoices')}
                                />
                        </div>

                    </div>
                </div>

                {saveSectionPreferencesBtn && <Button onClick={saveSectionPreferences}>Guardar secciones</Button>}
            </div>
        </div>
    );
};

export default Customization;
